<template>
    <div class="layout-header-section d-lg-flex d-block justify-content-between">
        <div class="header-label col-lg-6 col-12">Import Voters</div>
    </div>
    <div class="search-voter-section-outer" style="height: calc(100vh - 141px);">
        <div class="layout-content-section">
            <div class="form-box-outer">
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Upload CSV File <span class="text-danger">*</span></label>
                            <FileUpload mode="basic" accept=".csv" ref="importvoterfile" class="custom-upload-btn-ultima"
                                v-model="importvoterfile" v-on:change="handleUploadVoter()" />
                            <div class="custom-error" v-if="importmsg.file">
                                {{ importmsg.file }}
                            </div>
                            <div class="custom-error" v-if="importsystemmsg">
                                {{ importsystemmsg }}
                            </div>
                            <div class="text-success" v-if="importsystesuccessmsg">
                                {{ importsystesuccessmsg }}
                            </div>  
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12">
                        <div class="custom-form-group d-flex align-items-center justify-content-end"
                            style="margin-top: 26px;">
                            <label class="form-label mb-0">Download Sample Sheet : </label>
                            <div class="ms-3"><a class="custom-download-file"
                                    href="https://storage.googleapis.com/meraneta_admin/importvoters.csv" download="sample"><i
                                        class="pi pi-download"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="custom-form-group">
                            <!-- <label class="form-label me-3">Gather Voter and Aadhar data from the Government and Initiate WhatsApp BOT alerts?</label> -->
                            <label class="form-label me-3">Select which action to perform or which of the following data to collect from the government system, when the data is to be imported.</label>
                            <div>
                            <div class="form-check form-check-inline custom-inline-checkbox">
                                <input class="form-check-input" v-model="importcolumntype" type="checkbox"
                                    id="voterimport" value="1">
                                <label class="form-check-label" for="voterimport">Collect Voter Id Details</label>
                            </div>
                            <div class="form-check form-check-inline custom-inline-checkbox">
                                <input class="form-check-input" v-model="importcolumntype" type="checkbox"
                                    id="aadharimport" value="2">
                                <label class="form-check-label" for="aadharimport">Collect Aadhar Id Details</label>
                            </div>
                            <div class="form-check form-check-inline custom-inline-checkbox">
                                <input class="form-check-input" v-model="importcolumntype" type="checkbox"
                                    id="whatsappimport" value="3">
                                <label class="form-check-label" for="whatsappimport">Send Notification on WhatsApp</label>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">For Existing Records, Do you want to update the data?</label>
                            <div class="custom-group-radio-box-btn">
                                <input type="radio" class="btn-check" v-model="updatecolumntype" name="skip-option" id="skipupdate"
                                    autocomplete="off" value="0" />
                                <label class="btn custom-radio-box-btn text-capitalize" for="skipupdate">Skip</label>
                                <input type="radio" class="btn-check" v-model="updatecolumntype" name="update-option" id="yesupdate"
                                    autocomplete="off" value="1" />
                                <label class="btn custom-radio-box-btn text-capitalize" for="yesupdate">Update</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <div class="custom-form-group">
                        <button type="button" class="btn modal-bulk-next-btn" @click="importvoterfilebtn()" :disabled="importmsg.file != '' ||
                            importvoterfile == null ||
                            importvoterfile == '' || importfileloader
                            ">
                            <span v-if="!importfileloader">Submit</span>
                            <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                                v-if="importfileloader">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </button>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-7 col-md-7 col-12">
                        <div class="customer-label-group mb-1 d-flex align-items-center">
                            <div class="from-label-value text-primary">Notes :</div>
                        </div>
                        <div class="customer-label-group d-flex align-items-center">
                            <ol class="list-group list-group-flush">
                                <li class="form-label list-group-item">1. The file name should be same as sample file name "importvoters.csv".</li>
                                <li class="form-label list-group-item">2. Do not just change any files extension from .XLS or any other extension to CSV and upload it.</li>
                                <li class="form-label list-group-item">3. Don't change and delete any column name in CSV sheet.</li>
                            </ol>
                        </div>
                    </div>
                    <div class="col-lg-5 col-md-5 col-12">
                        <img src="/assets/images/import-dummy.png" title="Click to Fullscreen" @click="previewImageModalOpen()" class="img-fluid cursor-pointer" alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
        <!-- Preview screen images start here -->
        <div v-if="imagePreviewDialog" class="modal-mask">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Screen Preview</h4>
                    <button class="btn-close" @click="previewImageModalClose"></button>
                </div>
                <div class="modal-body p-0">
                    <img src="/assets/images/import-preview-img.jpg" style="vertical-align: middle; width: 100%;" />
                </div>
            </div>
        </div>
    </div>
    <!-- Preview screen images end here -->
</template>
<script>
import ApiService from "../service/ApiService";
export default {
    data() {
        return {
            importfileloader: false,
            importmsg: [],
            importvoterfile: "",
            importsystemmsg: '',
            importcolumntype: [],
            imagePreviewDialog: false,
            importsystesuccessmsg: '',
            updatecolumntype: '',
        }
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    methods: {
        previewImageModalClose() {
            this.imagePreviewDialog = false;
        },
        previewImageModalOpen() {
            this.imagePreviewDialog = true;
        },
        handleUploadVoter() {
            this.importsystemmsg = '';
            var file = this.$refs.importvoterfile.files[0];
            let allowedExtensions = /(\.csv)$/i;
            if (!file) {
                this.importmsg["file"] = "Please upload CSV excel file.";
            } else if (!file.size > 2048) {
                this.importmsg["file"] = "File size cannot exceed more than 2MB";
            } else if (!file.name.match(allowedExtensions)) {
                this.importmsg["file"] = "Invalid file type";
            } else {
                this.importmsg["file"] = "";
                this.importvoterfile = this.$refs.importvoterfile.files[0];
            }
        },
        importvoterfilebtn() {
            this.importfileloader = true;
            var formData = new FormData();
            formData.append("importfile", this.importvoterfile);
            formData.append("importfile55", JSON.stringify(this.importcolumntype));
            if(this.updatecolumntype) {
                formData.append("update", this.updatecolumntype);
            }
            this.ApiService.importvoters(formData).then((data) => {
                if (data.success == true) {
                    if (data.successflag == true) {
                        this.importsystesuccessmsg = data.message;
                        var successMsg = data.message;
                        this.$toast.open({
                            message: successMsg,
                            type: "success",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.importvoterfile = "";
                        this.updatecolumntype = '';
                        this.importcolumntype = [];
                    } else {
                        this.importsystemmsg = data.message;
                        var erroruploadMsg = data.message;
                        this.$toast.open({
                            message: erroruploadMsg,
                            type: "error",
                            duration: 3000,
                            position: "top-right",
                        });
                        this.importvoterfile = "";
                    }
                    this.importfileloader = false;
                    this.importvoterfile = "";
                } else {
                    this.importsystemmsg = data.message;
                    var errorMsg = data.message;
                    this.$toast.open({
                        message: errorMsg,
                        type: "error",
                        duration: 3000,
                        position: "top-right",
                    });
                }
            });
        },
    },
}
</script>
<style scoped>
.form-box-outer {
    padding: 16px 16px;
    border: 1px solid #e0e5ed;
    border-radius: 0px 0px 4px 4px;
}

.search-voter-section-outer {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 512px;
}

.search-voter-section-outer::-webkit-scrollbar {
    display: block;
}

.search-voter-section-outer::-webkit-scrollbar {
    width: 0.3em;
    height: 0.3em;
}

.search-voter-section-outer::-webkit-scrollbar-thumb {
    background-color: rgb(113 115 119 / 49%);
    border-radius: 3px;
}

.modal-bulk-next-btn.btn {
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    line-height: 20px;
    padding: 6px 35px;
    font-family: "AcuminPro-Regular";
}

.modal-bulk-next-btn:focus {
    box-shadow: none;
}

.custom-download-file {
    border: 1px solid #007bff;
    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
    margin: 0;
    user-select: none;
    vertical-align: bottom;
    overflow: hidden;
    background: #5266e5 0% 0% no-repeat padding-box;
    border-color: #5266e5;
    border-radius: 4px;
    letter-spacing: 0.26px;
    color: #ffffff;
    font-size: 13px;
    padding: 9px 11px;
    font-family: "AcuminPro-Regular";
}

.customer-label-group {
    margin-bottom: 16px;
}

.customer-label-group .form-label {
    font-family: "AcuminPro-Regular";
    font-size: 13px;
    letter-spacing: 0px;
    color: #4a5463;
    line-height: 13px;
    margin-bottom: 6px;
}

.customer-label-group .from-label-value {
    font-family: "AcuminPro-SemiBold";
    font-size: 14px;
    letter-spacing: 0.28px;
    color: #2b2e38;
    line-height: 20px;
}</style>